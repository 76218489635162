import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { BarLoader } from "react-spinners";
import { AbilityContext } from "../../../casl/Can";
import { Button, Modal, Row, Col, ModalBody, ModalHeader } from "reactstrap";
import LiveShareLink from "./LiveShareLink";
import moment from "moment";
import SummaryWidgets from "../SavedPlaces/Widgets/SummaryWidgets";
import ArrowImage from "../../../assets/img/arrow.png";
import { setLiveMonitoringAddress } from "../../../redux/actions/transporter/aTransporter-Geocoding";
import { connect } from "react-redux";
import {
  fileNameFormatWithType,
  onFetchAllAsync,
  handleRequestError
} from "../../../helpers/helper";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { FaDownload } from "react-icons/fa";
import {
  GOOGLE_GEOCODING_v2,
  LIVE_MONITORING_AVG_SPD,
} from "../../../redux/config/links";
import { activeFeature } from "../../../constants/constants";
import { toast } from "react-toastify";
import axios from "axios"

class LiveMonitoringDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFormValues: {},
      expandedRows: [],
      fleetsAvgSpeed: [],
      isFetchedAvgSpeed: false,
      liveMonitoringData: this.props.state.liveMonitoringDataCopy,
    };
  }

  UNSAFE_componentWillMount() {
    const [company] = this.props.companyListData.result;
    if (
      (Array.isArray(activeFeature.GET_ALL_ADDRESS_ON_LIVE_MONITORING) ===
        true &&
        !!activeFeature.GET_ALL_ADDRESS_ON_LIVE_MONITORING.find(
          (item) => item === company.id
        )) ||
      activeFeature.GET_ALL_ADDRESS_ON_LIVE_MONITORING === true
    ) {
      if (this.state.liveMonitoringData.length > 0) {
        const self = this;
        onFetchAllAsync(self.props.authStore.authData.result.token)(
          {
            url: GOOGLE_GEOCODING_v2,
            data: this.state.liveMonitoringData
              .filter((item) => item.lat !== null && item.lng !== null)
              .map((item) => ({ id: item.id, lat: item.lat, lng: item.lng })),
            type: "json",
          },
          {
            beforeSend: () => {
              self.setState({
                liveMonitoringData: [
                  ...this.state.liveMonitoringData.map((item) => ({
                    ...item,
                    isLoading: true,
                  })),
                ],
              });
            },
            success: (response) => {
              if (!!response) {
                const newFleetReportData = [
                  ...this.state.liveMonitoringData.map((item) => {
                    const dt = response.find(
                      (subitem) =>
                        JSON.parse(subitem.config.data).id === item.id
                    );
                    if (dt) {
                      return {
                        ...item,
                        address: dt.data.result.display_name,
                        lat: item.lat,
                        lng: item.lng,
                        isLoading: false,
                      };
                    }
                    return { ...item, isLoading: false };
                  }),
                ];
                self.setState({
                  liveMonitoringData: newFleetReportData,
                });
              }
            },
            error: () => {
              self.setState({
                liveMonitoringData: [
                  ...this.state.liveMonitoringData.map((item) => ({
                    ...item,
                    isLoading: false,
                  })),
                ],
              });
            },
          }
        );
      }
    }
  }

  componentDidMount() {
    //   console.log(this.props.state.liveMonitoringData)
  }

  componentWillUnmount() {
    //clear state
    this.props.setLiveMonitoringAddress([], true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { liveMonitoringAddress, indexReferenceById } = this.props;
    if (
      prevProps.state.liveMonitoringDataCopy !==
      this.props.state.liveMonitoringDataCopy
    ) {
      this.setState({
        liveMonitoringData: this.props.state.liveMonitoringDataCopy,
      });
    }
    if (prevProps.liveMonitoringAddress !== liveMonitoringAddress) {
      let editedLiveMonitoringData = [...this.state.liveMonitoringData];
      let liveMonitoringAddresslength = liveMonitoringAddress.length;
      let counter = 0;
      for (let i = 0; i < editedLiveMonitoringData.length; i++) {
        let id = editedLiveMonitoringData[i].id;
        let getIndex = indexReferenceById[id];
        if (getIndex !== undefined) {
          editedLiveMonitoringData[i] = liveMonitoringAddress[getIndex];
          counter += 1;
        }
        if (counter >= liveMonitoringAddresslength) {
          break;
        }
      }
      this.setState({
        liveMonitoringData: editedLiveMonitoringData,
      });
    }
  }

  fetchAvgSpd = (idFleet) => {
    const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    const auth = loginCreds.token;

    axios({
      method: "post",
      url: LIVE_MONITORING_AVG_SPD,
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
      },
      data: {
        id: idFleet,
        date: moment().format("YYYY-MM-DD"),
      },
    })
      .then((response) => {
        const data = response.data;
        if (data && data.result.length > 0) {
          const result = {
            id: data.result[0].id,
            avg_spd: data.result[0].avg_spd
              ? Math.floor(data.result[0].avg_spd)
              : 0,
          };
          this.setState((prevState) => ({
            fleetsAvgSpeed: [...prevState.fleetsAvgSpeed, result],
            isFetchedAvgSpeed: true,
          }));
        } else {
          this.setState({ isFetched: false });
          toast.error("Failed to fetch Average Speed, Please try again.");
        }
      })
      .catch((err) => {
        this.setState({ isFetched: false });
        handleRequestError(err);
      });
  };

  latDataFormat = (cell, row) => {
    if (row.isLoading)
      return (
        <center>
          <BarLoader color={"#123abc"} loading={row.isLoading} /> <br />{" "}
          Loading...
        </center>
      );
    if (row.lat === null || row.lng === null) {
      return <span>-</span>;
    } else {
      return (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.onPositionClicked(row.lat, row.lng)}
          >
            {row.lat} , {row.lng}
          </span>
          <img
            style={{
              paddingLeft: "2px",
              transform: `rotate(${row.radian}rad)`,
            }}
            src={ArrowImage}
            alt=""
          />
          <br />
          {row.address ? (
            <div style={{ whiteSpace: "pre-line" }}>{row.address}</div>
          ) : (
            <span
              style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
              onClick={() => this.onShowAdressClicked(row)}
            >
              show address
            </span>
          )}
        </div>
      );
    }
  };

  latLngDataFormatCSV = (cell, row) => {
    if (row.lat === null || row.lng === null) {
      return "-";
    }
    if (row.address) {
      return `${row.lat}, ${row.lng} - ${row.address}`;
    } else {
      return `${row.lat}, ${row.lng} - Address not available`;
    }
  };

  onPositionClicked = (lat, lng) => {
    window.open(`https://www.google.com/maps?q=loc:@${lat},${lng}`, "_blank");
  };

  onShowAdressClicked = (row) => {
    this.props.setLiveMonitoringAddress(row);
  };

  lngDataFormat = (cell, row) => {
    if (row.lng === null) {
      return <span>-</span>;
    } else {
      return <span>{row.lng}</span>;
    }
  };

  fleetOnIdleTimeCheck = (row) => {
    const getOnIdleTimeDiffMin = moment.duration(
      moment().diff(row.last_update_with_speed)
    );
    if (getOnIdleTimeDiffMin.asMinutes() >= 30) {
      return (
        <div style={{ backgroundColor: "orange", fontWeight: "bold" }}>
          {`${Number(getOnIdleTimeDiffMin.asMinutes()).toFixed(2)} min.`}
        </div>
      );
    }
    if (getOnIdleTimeDiffMin.asMinutes() > 1) {
      return `${Number(getOnIdleTimeDiffMin.asMinutes()).toFixed(2)} min.`;
    }
    return `${Number(getOnIdleTimeDiffMin.asSeconds()).toFixed(2)} sec.`;
  };

  fleetStatusFormat = (cell, row) => {
    if (!row.time) return <span>-</span>;
    if (row.wrn === 0 && row.spd > 0) {
      return <span>Off (Warning!)</span>;
    }
    if (row.wrn === 0) {
      return <span>Off</span>;
    } else {
      if (row.spd > 0) {
        return <span>On & Moving</span>;
      }
      if (row.spd === 0) {
        return (
          <span>
            On & Idle, <br />
            {this.fleetOnIdleTimeCheck(row)}
          </span>
        );
      }
    }
  };

  fleetStatusCsvFormat = (cell, row) => {
    if (!row.time) return "-";
    if (row.wrn === 0 && row.spd > 0) {
      return "Off (Warning!)";
    }
    if (row.wrn === 0) {
      return "Off";
    } else {
      if (row.spd > 0) {
        return "On & Moving";
      }
      if (row.spd === 0) {
        return `On & Idle, ${this.fleetOnIdleTimeCheck(row)}`;
      }
    }
  };

  tempStatusFormat = (cell, row) => {
    if (cell) {
      return <span>{cell} °C</span>;
    } else {
      return <span>-</span>;
    }
  };

  tempStatusCsvFormat = (cell) => {
    if (cell) {
      return `${cell} °C`;
    } else {
      return "-";
    }
  };

  fuelLevelFormat = (cell, row) => {
    if (cell) return `± ${cell}%`;
  };

  fuelLevelCsvFormat = (cell) => {
    if (cell) return `± ${cell}%`;
  };

  doorStatus = (cell, row) => {
    let isDoorSensorBind = false;
    if (isDoorSensorBind) {
      if (cell > 0) {
        return <span>Open</span>;
      } else {
        return <span>Close</span>;
      }
    } else {
      return <span>-</span>;
    }
  };

  doorStatusCsvFormat = (cell, row) => {
    let isDoorSensorBind = false;
    if (isDoorSensorBind) {
      if (cell > 0) {
        return "Open";
      } else {
        return "Close";
      }
    } else {
      return "-";
    }
  };

  bleStatus = (cell) => {
    if (cell) {
      return cell;
    }
    return "-";
  };

  bleStatusCsvFormat = (cell) => {
    if (cell) {
      return cell;
    }
    return "-";
  };

  dateTimeCsvFormat = (cell) => {
    if (!cell) return "-";
    return moment(cell).format("YYYY-MM-DD HH:mm:ss");
  };

  handleAfterSearch = (searchText, result) => {
    this.props.onDataSearch(result);
  };

  handleAfterColumnFilter = (filterConds, result) => {
    this.props.onDataSearch(result);
  };

  delayStatusFormatter = (cell, row) => {
    if (!cell) return;
    return (
      <center>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <circle fill={this.getStatusColor(cell, row)} cx="8" cy="8" r="8" />
        </svg>
      </center>
    );
  };

  delayStatusFormatterCSV = (cell, row) => {
    if (!cell) return "-";
    const color = this.getStatusColor(cell, row);
    if (color === "#d24d57") return "Delayed";
    if (color === "#fff000") return "Warning";
    if (color === "#C8E6B2") return "On Time";
    return "Unknown";
  };

  getStatusColor = (cell, row) => {
    let currentDuration = moment.duration(moment().diff(cell));
    if (currentDuration.asMinutes() >= 60 && row.wrn === 1) return "#d24d57";
    if (currentDuration.asMinutes() >= 1440 && row.wrn === 0) return "#d24d57";
    if (
      currentDuration.asMinutes() > 15 &&
      currentDuration.asMinutes() < 60 &&
      row.wrn === 1
    )
      return "#fff000";
    if (currentDuration.asMinutes() <= 15) return "#C8E6B2";
    if (currentDuration.asMinutes() > 15 && row.wrn === 0) return "#C8E6B2";
  };

  getExcelData = () => {
    return this.state.liveMonitoringData.map((item) => ({
      ...item,
      lat_lng: this.latLngDataFormatCSV(null, item),
      fleet_status: this.fleetStatusCsvFormat(null, item),
      temperature: this.tempStatusCsvFormat(item.temp_c),
      fuel_level: this.fuelLevelCsvFormat(item.fuel_level),
      door_status: this.doorStatusCsvFormat(null, item),
      ble_status: this.bleStatusCsvFormat(item.ble_status),
      date_time: this.dateTimeCsvFormat(item.time),
      delay_status: this.delayStatusFormatterCSV(item.time, item),
      obd_code: item.obd_code || "-",
      spd: item.spd || "-",
    }));
  };

  exportToExcel = async () => {
    const data = this.getExcelData();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Live Monitoring Data");

    worksheet.columns = [
      { header: "Delay Status", key: "delay_status" },
      { header: "Registration Number", key: "reg_number_with_door" },
      { header: "OBD Code", key: "obd_code" },
      { header: "Position", key: "lat_lng" },
      { header: "Fleet Status", key: "fleet_status" },
      { header: "Car Brands", key: "car_brands" },
      { header: "Car Type", key: "car_type" },
      { header: "Temperature", key: "temperature" },
      { header: "Speed", key: "spd" },
      { header: "Fuel Level", key: "fuel_level" },
      { header: "Door Status", key: "door_status" },
      { header: "BLE Status", key: "ble_status" },
      { header: "Date & Time", key: "date_time" },
    ];

    // Tambahin data ke worksheet
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Font style untuk semua kolom
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.font = {
          name: "Inter",
          family: 2,
          size: 12,
        };
      });
    });

    // Font style bold untuk header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: "Inter", bold: true };
    });

    // Font style & font color utk kolom tertetu
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        row.eachCell((cell, colNumber) => {
          if ([4, 5, 11].includes(colNumber)) {
            // 4 untuk lat_lng, 5 untuk fleet_status, 11 untuk date_time
            cell.font = {
              name: "Inter",
              color: { argb: "FF0000FF" }, // Warna biru
              family: 2,
              size: 12,
            };
          }
        });
      }
    });

    // Nyimpen workbook ke file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = fileNameFormatWithType("LiveMonitoringDataFleet", "xlsx");
    saveAs(blob, fileName);
  };

  ExpandRow = ({ row }) => (
    <Row>
      <Col>
        <p>
          <b>Door Rear Status </b>
          {row.door_rear ? this.doorStatus(row.door_rear) : "-"}
        </p>

        <p>
          <b>Left Door Status </b>
          {row.door_left ? this.doorStatus(row.door_left) : "-"}
        </p>
        <p>
          <b>Right Door Status </b>
          {row.door_right ? this.doorStatus(row.door_right) : "-"}
        </p>
      </Col>
      <Col>
        <p>
          <b>Temperature </b>
          {row.temp_c ? this.tempStatusFormat(row.temp_c) : "-"}
        </p>
        <p>
          <b>BLE: </b>
          {row.ble ? this.bleStatus(row.ble) : "-"}
        </p>
        <p>
          <b>Average Speed: </b>
          {this.state.fleetsAvgSpeed.length > 0 &&
          this.state.isFetchedAvgSpeed ? (
            this.state.fleetsAvgSpeed.find((item) => item.id === row.id) ? (
              `${
                this.state.fleetsAvgSpeed.find((item) => item.id === row.id)
                  .avg_spd
              } KM/h`
            ) : (
              <span
                style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
                onClick={() => this.fetchAvgSpd(row.id)}
              >
                Calculate
              </span>
            )
          ) : (
            <span
              style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
              onClick={() => this.fetchAvgSpd(row.id)}
            >
              Calculate
            </span>
          )}
        </p>
      </Col>
    </Row>
  );

  isRowExpanded = (rowId) => this.state.expandedRows.includes(rowId);

  handleExpandRow = (rowId) => {
    if (this.isRowExpanded(rowId)) {
      this.setState({
        expandedRows: this.state.expandedRows.filter((id) => id !== rowId),
      });
    } else {
      this.setState({
        expandedRows: [...this.state.expandedRows, rowId],
      });
    }
  };

  focusLinkFormater = (cell, row) => {
    return (
      <>
        <Button
          color="primary"
          size="sm"
          onClick={() => this.toggleOpenLiveShare(row.id, row.reg_number)}
        >
          <i className="fa fa-share-alt"></i>
        </Button>
        <span
          onClick={() => this.handleExpandRow(row.id)}
          style={{
            cursor: "pointer",
            color: "blue",
            marginLeft: "10px",
          }}
        >
          {this.isRowExpanded(row.id) ? <b>Hide</b> : <b>Show all</b>}
        </span>
      </>
    );
  };

  showDatatables = () => {
    const options = {
      noDataText: "No vehicle to monitor yet!",
      afterSearch: this.handleAfterSearch,
      afterColumnFilter: this.handleAfterColumnFilter,
      exportCSVSeparator: ";",
      btnGroup: (props) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {props.exportCSVBtn}{" "}
          {false && (
            <div>
              <Button
                color="primary"
                onClick={this.exportToExcel}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <FaDownload /> Export to Excel
              </Button>
            </div>
          )}
        </div>
      ),
      onRowClick: (row) => this.handleExpandRow(row.id),
    };

    return (
      <div>
        <BootstrapTable
          data={this.state.liveMonitoringData}
          version="4"
          hover
          pagination
          search
          edit
          bordered={false}
          exportCSV={true}
          csvFileName={fileNameFormatWithType("LiveMonitoringDataFleet", "csv")}
          multiColumnSearch
          ref="btable"
          expandableRow={() => true}
          expandComponent={(row) => <this.ExpandRow row={row} />}
          options={options}
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            thStyle={{ whiteSpace: "normal" }}
            width="10%"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="company"
            searchable
            dataSort
            csvHeader="Company"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="15%"
            filter={{ type: "TextFilter", delay: 1000 }}
            hidden={
              this.context.can(
                "view",
                "company_field@live_monitoring_datatable"
              )
                ? false
                : true
            }
          >
            Company
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="time"
            csvHeader="Time"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="7%"
            dataFormat={this.delayStatusFormatter}
            csvFormat={this.delayStatusFormatterCSV}
          ></TableHeaderColumn>
          <TableHeaderColumn
            dataField="reg_number_with_door"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataFormat={this.clickableIdFleet.bind(this)}
            csvFormat={(cell) => cell || "-"}
            searchable
            dataSort
            csvHeader="Registration Number"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="25%"
          >
            ID Fleet
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="obd_code"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            csvHeader="OBD Code"
            csvFormat={(cell) => cell || "-"}
            thStyle={{ whiteSpace: "normal" }}
            width="26%"
          >
            OBD Code
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="lat"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            csvHeader="Position"
            thStyle={{ whiteSpace: "normal" }}
            width="36%"
            dataFormat={this.latDataFormat.bind(this)}
            csvFormat={this.latLngDataFormatCSV}
          >
            Position
          </TableHeaderColumn>
          {/* <TableHeaderColumn
          dataField="lng"
          csvHeader="Longitude"
          thStyle={{ whiteSpace: "normal" }}
          width="0%"
        ></TableHeaderColumn> */}
          <TableHeaderColumn
            dataField="fuel_level"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            csvHeader="Fuel Level"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="15%"
            dataFormat={this.fuelLevelFormat.bind(this)}
            csvFormat={this.fuelLevelCsvFormat}
          >
            Fuel Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="spd"
            filter={{ type: "NumberFilter", delay: 1000 }}
            searchable
            dataSort
            csvHeader="Speed"
            csvFormat={(cell) => cell || "-"}
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="24%"
          >
            Speed (km/h)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="wrn"
            filter={{
              type: "SelectFilter",
              options: { 0: "Off", 1: "On" },
              condition: "eq",
            }}
            searchable
            dataSort
            csvHeader="Fleet Status"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
            dataFormat={this.fleetStatusFormat.bind(this)}
            csvFormat={this.fleetStatusCsvFormat}
          >
            Fleet Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="car_brands"
            filter={{
              type: "SelectFilter",
              options: { 0: "Off", 1: "On" },
              condition: "eq",
            }}
            searchable
            dataSort
            csvHeader="Car Brands"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
          >
            Car Brands
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="car_type"
            filter={{
              type: "SelectFilter",
              options: { 0: "Off", 1: "On" },
              condition: "eq",
            }}
            searchable
            dataSort
            csvHeader="Car Type"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
          >
            Car Type
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="temp_c"
            filter={{ type: "NumberFilter", delay: 1000 }}
            searchable
            dataSort
            hidden
            csvHeader="Temperature"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="14%"
            // dataFormat={this.tempStatusFormat.bind(this)}
            csvFormat={this.tempStatusCsvFormat}
          >
            Temperature
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="door_left"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            hidden
            csvHeader="Left Door Status"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
            // dataFormat={this.doorStatus.bind(this)}
            csvFormat={this.doorStatusCsvFormat}
          >
            L. Door Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="door_right"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            hidden
            csvHeader="Right Door Status"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
            // dataFormat={this.doorStatus.bind(this)}
            csvFormat={this.doorStatusCsvFormat}
          >
            R. Door Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="door_rear"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            hidden
            csvHeader="Rear Door Status"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="19%"
            // dataFormat={this.doorStatus.bind(this)}
            csvFormat={this.doorStatusCsvFormat}
          >
            RR. Door Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="ble"
            filter={{ type: "TextFilter", delay: 1000 }}
            searchable
            dataSort
            hidden
            csvHeader="BLE"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="18%"
            // dataFormat={this.bleStatus.bind(this)}
            csvFormat={this.bleStatusCsvFormat}
          >
            BLE
          </TableHeaderColumn>
          {/* <TableHeaderColumn dataField="fleet_plate" searchable dataSort csvHeader="Fleet Plate" thStyle={ { whiteSpace: 'normal' } } width="20%">Fleet Plate</TableHeaderColumn>
              <TableHeaderColumn dataField="id_driver" dataSort csvHeader="ID Driver" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%" hidden>ID Driver</TableHeaderColumn>
              <TableHeaderColumn dataField="driver_name" searchable dataSort csvHeader="Driver Name" thStyle={ { whiteSpace: 'normal' } } width="15%">Driver Name</TableHeaderColumn>
              <TableHeaderColumn dataField="id_driver_assistant" dataSort csvHeader="ID Driver Assistant" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%" hidden>ID Driver Assistant</TableHeaderColumn>
              <TableHeaderColumn dataField="driver_assistant_name" dataSort csvHeader="Driver Assistant Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Driver Assistant Name</TableHeaderColumn>
              <TableHeaderColumn dataField="do_number" dataSort csvHeader="DO Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">DO Number</TableHeaderColumn>
              <TableHeaderColumn dataField="do_desc" dataSort csvHeader="DO Desc" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">DO Desc</TableHeaderColumn>
              <TableHeaderColumn dataField="do_assigned_date" dataSort csvHeader="DO Assigned Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">DO Assigned Date</TableHeaderColumn> */}
          <TableHeaderColumn
            dataField="time"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataSort
            csvHeader="Timestamp"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="20%"
            dataFormat={this.dateTimeFormatter}
            csvFormat={this.dateTimeCsvFormat}
          >
            Timestamp
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="action"
            export={false}
            dataFormat={this.focusLinkFormater.bind(this)}
            width="28%"
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  };

  clickableIdFleet = (cell, row) => {
    return (
      <div
        style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
        onClick={() => this.onIdFleetClicked(row)}
      >
        {cell}
      </div>
    );
  };

  dateTimeFormatter = (cell) => {
    if (!cell) return "-";
    return moment(cell).format("YYYY-MM-DD HH:mm:ss");
  };

  onIdFleetClicked = (row) => {
    this.props.onIdFleetClicked(row);
  };

  toggleOpenLiveShare = (_id_fleet, _reg_number) => {
    this.setState({
      modalLiveShare: !this.state.modalLiveShare,
      initialFormValues: {
        link_lifetime: { label: "1 Hour", value: 1 },
        id_fleet: _id_fleet,
        reg_number: _reg_number,
      },
    });
  };

  toggleCloseLiveShare = () => {
    this.setState({
      modalLiveShare: !this.state.modalLiveShare,
    });
  };

  linkHeaderClickHandler = (type) => {
    if (type === "unsigned-fleet" || type === "assigned-fleet") {
      this.props.history.push(`/transporter/fleets?type=${type}`);
    } else {
      this.props.onLinkHeaderClicked(type);
    }
  };

  percentage = (current, all) => {
    return ((Number(current) / Number(all)) * 100).toFixed(2);
  };

  DelayStatusSummary = (props) => {
    const { summaries } = this.props;
    return (
      <>
        <Row>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={summaries.allFleets}
              headerLink={"all-fleets"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"All Fleet(s)"}
              icon={"fa fa-car"}
              color={"primary"}
            />
          </Col>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={`${this.props.totalAssignedFleet} (${this.percentage(
                this.props.totalAssignedFleet,
                summaries.allFleets
              )}%)`}
              headerLink={"assigned-fleet"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"OBD Assigned Fleet(s)"}
              icon={"fa fa-car"}
              color={"success"}
            />
          </Col>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={`${this.props.totalUnsignedFleet} (${this.percentage(
                this.props.totalUnsignedFleet,
                summaries.allFleets
              )}%)`}
              headerLink={"unsigned-fleet"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"OBD Unsigned Fleet(s)"}
              icon={"fa fa-car"}
              color={"warning"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={`${summaries.normal} (${this.percentage(
                summaries.normal,
                summaries.allFleets
              )}%)`}
              headerLink={"on-time"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"Normal 1 - 15 Min."}
              icon={"fa fa-clock-o"}
              color={"success"}
            />
          </Col>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={`${summaries.delayed} (${this.percentage(
                summaries.delayed,
                summaries.allFleets
              )}%)`}
              headerLink={"delay"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"16 - 60 Min. Delay"}
              icon={"fa fa-clock-o"}
              color={"warning"}
            />
          </Col>
          <Col md={4} xs={12}>
            <SummaryWidgets
              header={`${summaries.longDelayed} (${this.percentage(
                summaries.longDelayed,
                summaries.allFleets
              )}%)`}
              headerLink={"long-delay"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"> 60 Min. Delay"}
              icon={"fa fa-clock-o"}
              color={"danger"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <SummaryWidgets
              header="Fleet status indicator"
              mainText={
                <>
                  <span style={{ color: "green" }}>
                    Green circles: Idle state
                  </span>
                  <br />
                  <span style={{ color: "orange" }}>
                    Yellow circles: Moving
                  </span>
                </>
              }
              icon="fa fa-car"
              color="dark"
            />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { initialFormValues } = this.state;
    return (
      <div>
        {this.props.state.isLoading ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.props.state.isLoading} />
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <>
            <this.DelayStatusSummary />
            {this.showDatatables()}
          </>
        )}
        <Modal
          isOpen={this.state.modalLiveShare}
          toggle={this.toggleOpenLiveShare}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader toggle={this.toggleCloseLiveShare}>
            Generate Share Link (
            {initialFormValues.reg_number ? initialFormValues.reg_number : ""})
          </ModalHeader>
          <ModalBody>
            <LiveShareLink
              initialValues={initialFormValues}
              formClose={this.toggleCloseLiveShare}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  liveMonitoringAddress: state.liveMonitoringAddressStore.liveMonitoringAddress,
  indexReferenceById: state.liveMonitoringAddressStore.indexReferenceById,
  totalFleet: state.fleetListTableStore.totalFleet,
  totalAssignedFleet: state.fleetListTableStore.totalAssignedFleet,
  totalUnsignedFleet: state.fleetListTableStore.totalUnsignedFleet,
  companyListData: state.companyStore.companyListData,
  authStore: state.authStore,
});
LiveMonitoringDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, { setLiveMonitoringAddress })(
  LiveMonitoringDatatable
);
