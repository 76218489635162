import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {Redirect} from 'react-router';
import {connect} from "react-redux";
import { fetchLogin } from '../../../redux/actions/aUser-Auth.jsx';
import 'react-toastify/dist/ReactToastify.css';
import logoAutoma from '../../../assets/img/new-automa-logo.png'
import { Formik } from 'formik'
import * as Yup from 'yup'

class Login extends Component {
  constructor (props) {
    super(props);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.setLogin = this.setLogin.bind(this);
    this.state = {
      email: '',
      password: '',
      formErrors: {email: '', password: ''},
      emailValid: false,
      passwordValid: false,
      formValid: false,
      login:false,
      sessionLoginToken:''
    };
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({value:e.target.value});
    this.setState({[name]: value},
    () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid
                  }, this.validateForm(this));
  }

  // handleSubmit(e){
  //   e.preventDefault();
  //   var username = document.querySelector('#emailUser').value;
  //   var password = document.querySelector('#passUser').value;
  //   this.props.fetchLogin(username, password);
  // }


  render() {
    // const { from } = this.props.location.state || '/login'
    const isAuthenticated = () => {
      if(this.props.isAuthenticated === true) {
          return (<Redirect to="/welcome" />)
      }
      else {
          return null;
      }
    }
    return (
      <div className="app flex-row align-items-center login-body"> 
      {isAuthenticated()}
      
      {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    {/* <Form onSubmit={this.handleSubmit}> */}
                     <img src={logoAutoma} alt="Automa Logo" className="automa-logo-login"/>
                      <div style={{"paddingTop":"45px"}}>
                        <p className="text-muted">Sign In to Automa Apps 2.54</p>

                        <Formik
                          initialValues={{ email: "", password: "" }}
                          onSubmit={(values, {setStatus,setSubmitting}) => {
                            setStatus(undefined);
                            this.props.fetchLogin(values['email'], values['password'], ()=>{
                              setSubmitting(false)
                            })
                            // setTimeout(() => {
                            //   // setting API errors
                            //   actions.setStatus({
                            //     email: 'This is email already exists.',
                            //     pswrd: 'This is password is incorrect',
                            //   });
                            // }, 500);
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string()
                              .email()
                              .required("Required"),
                            password: Yup.string().required('Password required').trim().min(8, 'Password should be connsisting of min. 8 characters'),
                          })}
                        >
                          {props => {
                            const {
                              values,
                              touched,
                              errors,
                              // dirty,
                              isSubmitting,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              // handleReset
                              isValid
                            } = props;
                            return (
                              <form onSubmit={handleSubmit}>
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-user"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input 
                                    id="email"
                                    placeholder="Enter your email"
                                    type="text"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors.email && touched.email
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  />
                                </InputGroup>
                                {errors.email && touched.email && (
                                  <div className="input-feedback">{errors.email}</div>
                                )}
                                <InputGroup className="mb-4">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input 
                                    id="password"
                                    placeholder="Password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors.password && touched.password
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  />
                                </InputGroup>
                                {errors.password && touched.password && (
                                  <div className="input-feedback">{errors.password}</div>
                                )}
                                {/* <label htmlFor="email" style={{ display: "block" }}>
                                  Email
                                </label>
                                <input
                                  id="email"
                                  placeholder="Enter your email"
                                  type="text"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.email && touched.email
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {errors.email && touched.email && (
                                  <div className="input-feedback">{errors.email}</div>
                                )} */}

                                {/* <button
                                  type="button"
                                  className="outline"
                                  onClick={handleReset}
                                  disabled={!dirty || isSubmitting}
                                >
                                  Reset
                                </button>
                                <button type="submit" disabled={isSubmitting}>
                                  Submit
                                </button> */}
                                <Row>
                                  <Col xs="6" >
                                    <Button color="link" className="px-0" href="mailto:rizky@automa.id?cc=sales@automa.id,&subject=Subscribe%20Automa%20Platform&body=Saya%20ingin%20tahu%20lebih%20jauh%20mengenai%20Automa%20IoT%20Platform">Interested? Contact us!</Button>
                                  </Col>
                                  <Col xs="6" className="text-right">
                                    <Button color="primary" type="submit" className="px-4" disabled={isSubmitting || !isValid}>Login</Button>
                                  </Col>
                                  {/* <Col xs="6" className="text-right">
                                    <Button color="link" className="px-0">Forgot password?</Button>
                                  </Col> */}
                                  {/* {this.props.isAuthenticated && (
                                        <Redirect to={from || '/dashboard'}/>)} */}
                                </Row>
                              </form>
                            );
                          }}
                        </Formik>

  {/* 

                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="email" placeholder="Email" autoComplete="email" id="emailUser"/>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" placeholder="Password" autoComplete="current-password" id="passUser"/>
                        </InputGroup> */}
                        <Row>
                          {/* <Col xs="6" >
                            <Button color="link" className="px-0" href="mailto:rizky@automa.id?cc=sales@automa.id,&subject=Subscribe%20Automa%20Platform&body=Saya%20ingin%20tahu%20lebih%20jauh%20mengenai%20Automa%20IoT%20Platform">Interested? Contact us!</Button>
                          </Col> */}
                          {/* <Col xs="6" className="text-right">
                            <Button color="primary" type="submit" className="px-4" disabled={this.props.inProgress}>Login</Button>
                          </Col> */}
                          {/* <Col xs="6" className="text-right">
                            <Button color="link" className="px-0">Forgot password?</Button>
                          </Col> */}
                          {/* {this.props.isAuthenticated && (
                                <Redirect to={from || '/dashboard'}/>)} */}
                        </Row>
                      {/* </Form> */}
                    </div>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>One Stop Logistic IOT Solution for Your Business</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card> */}
              </CardGroup>
            </Col>
          </Row>
          <div className='login-page-footer'>
                <p className='login-page-footer-text'>Background photo by 
                      <a href='https://www.pexels.com/photo/high-angle-shot-of-colorful-trucks-2226457/' 
                      rel="noopener noreferrer" target="_blank"> Tom Fisk</a>
                </p>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      // authData : state.authStore.authData,
      inProgress: state.authStore.inProgress,
      isAuthenticated: state.authStore.isAuthenticated
  }
}

export default connect(mapStateToProps, {fetchLogin}) (Login);
// export default Login;