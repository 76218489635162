import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
import LoadingContent from "src/components/LoadingContent/LoadingContent";
import useForm, { ICallbackSubmit } from "src/hooks/useForm";
import useLazyFetch from "src/hooks/useLazyFetch";
import { CHECK_SHEET_DETAIL_LIST_URL, CHECK_SHEET_LIST_URL, CHECK_SHEET_WEEKLY_ADD, CHECK_SHEET_WEEKLY_DETAIL_INPUT, CHECK_SHEET_WEEKLY_LIST } from "src/redux/config/links";
import * as yup from "yup";
import Select from "react-select";
import { onFetchArrayAsync } from "src/helpers/helper";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store.type";

const optsDays = [
    {
        value: 0,
        name: "Sunday"
    },
    {
        value: 1,
        name: "Monday"
    },
    {
        value: 2,
        name: "Tuesday"
    },
    {
        value: 3,
        name: "Wednesday"
    },
    {
        value: 4,
        name: "Thursday"
    },
    {
        value: 5,
        name: "Friday"
    },
    {
        value: 6,
        name: "Saturday"
    },
]

interface IProps {
    fleet: {
        id: number;
    };
    onClose: () => void;
    initialValues?: any;
    isEdit: boolean;
}

const BindCheckSheet: React.FC<IProps> = ({ fleet, isEdit, onClose, initialValues }) => {

    const {authStore} = useSelector((state:RootState)=>({authStore:state.authStore}))
    const [isGetting, setIsGetting] = useState(true);
    const [isGettingDetailList, setIsGettingDetail] = useState(true);

    const [getCheckSheetWeeklyList] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_LIST,
            method: "POST",
        });

    const [getCheckSheetList, { loading: loadingGetWithdrawalList, data }] =
        useLazyFetch({
            url: CHECK_SHEET_LIST_URL,
            method: "POST",
        });
    const [getCheckSheetDetailList, { loading: loadingGetCheckSheetDetailList, data: dataCheckSheetDetailList }] =
        useLazyFetch({
            url: CHECK_SHEET_DETAIL_LIST_URL,
            method: "POST",
        });
    const [addCheckSheetWeekly] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_ADD,
            method: "POST",
            type: "json"
        });

    const validation = () => {
        const selectYup = yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable();
        return yup.object().shape({
            check_sheet: selectYup.required("Check Sheet is required!"),
            reminder_day: selectYup.required("Reminder Day is required!"),
            reminder_hour: selectYup.required("Reminder Hour is required!"),
        });
    };
    const onSubmit: ICallbackSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)
        addCheckSheetWeekly({
            body: {
                id_check_sheet: values.check_sheet.value,
                id_fleet: fleet.id,
                reminder_day: values.reminder_day.value,
                reminder_hour: Number(values.reminder_hour.value)
            }
        }, (response) => {
            onFetchArrayAsync(authStore.authData.result.token)(values.check_sheet_detail.map((item: number) => ({
                url: CHECK_SHEET_WEEKLY_DETAIL_INPUT,
                method: "POST",
                data: {
                    id_check_sheet_weekly: response?.data.result.id,
                    id_check_sheet_detail: item
                },
                type: "json"
            })), {
                beforeSend: () => null,
                success: () => {
                    setSubmitting(false)
                    onClose();
                },
                error: () => {
                    setSubmitting(false)
                    onClose();
                }
            })
        })
    };

    const {
        handleSubmit,
        errors,
        values,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
    } = useForm({
        initialValues: {
            check_sheet: !!initialValues ? {
                label: initialValues.check_sheet_name,
                value: initialValues.id_check_sheet
            } : null,
            reminder_day: !!initialValues ? {
                label: optsDays.find((item) => item.value === initialValues.reminder_day)?.name,
                value: initialValues.reminder_day
            } : null,
            reminder_hour: !!initialValues ? { label: initialValues.reminder_hour, value: initialValues.reminder_hour } : null,
            check_sheet_detail: !!initialValues ? initialValues.check_sheet_detail : []
        },
        validation: validation(),
    });

    useEffect(() => {
        if (isGetting) {
            setIsGetting(false);
            getCheckSheetWeeklyList({
                body: {
                    id_fleet: fleet.id
                }
            }, () => {
                getCheckSheetList({});
            })
        }
    }, [isGetting, getCheckSheetList, fleet, getCheckSheetWeeklyList]);

    useEffect(() => {
        if (isGettingDetailList) {
            setIsGettingDetail(false);
            if (initialValues && initialValues.id_check_sheet) {
                getCheckSheetDetailList({
                    body: {
                        id_check_sheet: initialValues.id_check_sheet
                    }
                });
            }
        }

    }, [isGettingDetailList, initialValues, getCheckSheetDetailList])
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Check Sheet</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <LoadingContent
                            loading={loadingGetWithdrawalList}
                            onReload={() => { setIsGetting(true); return null }}
                        >
                            <Select
                                value={values.check_sheet}
                                onChange={(value: any) => {
                                    setFieldValue("check_sheet", value);
                                    getCheckSheetDetailList({
                                        body: {
                                            id_check_sheet: value.value
                                        }
                                    });
                                }}
                                options={(data ? data.result : []).map((item: { check_sheet_name: any; id: any; }) => ({
                                    label: item.check_sheet_name,
                                    value: item.id,
                                }))}
                                disabled={isSubmitting}
                                placeholder={"Choose Check Sheet"}
                            />
                        </LoadingContent>
                        <FormFeedback>{errors.check_sheet}</FormFeedback>
                    </Col>
                </FormGroup>
                {values.check_sheet !== null ?
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Detail</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <LoadingContent
                                loading={loadingGetCheckSheetDetailList}
                                onReload={() => {

                                    getCheckSheetDetailList({
                                        body: {
                                            id_check_sheet: values.check_sheet.value
                                        }
                                    });
                                    return null;
                                }}
                            >
                                <>
                                    {(dataCheckSheetDetailList ? dataCheckSheetDetailList.result : []).map((item: { activity_name: string; id: number; desc: string }, index: React.Key) => <React.Fragment key={index}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: ".5rem" }}><div>
                                            <input type="checkbox" name="id_check_sheet_detail" value={item.id} checked={!!values.check_sheet_detail.find((i: number) => i === item.id)} onClick={() => {

                                                if (!!values.check_sheet_detail.find((i: number) => i === item.id)) {
                                                    setFieldValue("check_sheet_detail", [...values.check_sheet_detail].filter((i) => i != item.id));
                                                }
                                                else {
                                                    setFieldValue("check_sheet_detail", [...values.check_sheet_detail, item.id]);
                                                }
                                            }} /></div>
                                            <div>
                                                {item.activity_name}
                                                <p style={{ opacity: .5 }}>{item.desc}</p></div></div>
                                    </React.Fragment>)}
                                </>
                            </LoadingContent>
                        </Col>
                    </FormGroup> : null}
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Reminder Day</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={values.reminder_day}
                            onChange={(value: any) => {
                                setFieldValue("reminder_day", value);
                            }}
                            options={(optsDays).map((item) => ({
                                label: item.name,
                                value: item.value,
                            }))}
                            disabled={isSubmitting}
                            placeholder={"Choose Reminder Day"}
                        />
                        <FormFeedback>{errors.reminder_day}</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Reminder Hour</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={values.reminder_hour}
                            onChange={(value: any) => {
                                setFieldValue("reminder_hour", value);
                            }}
                            options={([
                                "0", "1", "2", '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
                            ]).map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            disabled={isSubmitting}
                            placeholder={"Choose Reminder Hour"}
                        />
                        <FormFeedback>{errors.reminder_hour}</FormFeedback>
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Button
                        type="submit"
                        color="primary"
                        className="mr-1"
                        disabled={isSubmitting || !isValid}
                    >
                        {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
                    </Button>
                    <Button
                        type="button"
                        color="warning"
                        className="mr-1"
                        disabled={isSubmitting}
                        onClick={() => onClose()}
                    >
                        Close
                    </Button>
                    <Button
                        type="reset"
                        color="danger"
                        className="mr-1"
                        disabled={isSubmitting}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </FormGroup>
            </form>
        </>
    );
}

export default BindCheckSheet;